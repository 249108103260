import * as yup from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useContext } from "react"
import { MainContext } from "../../../App"
import { postLoginFetch } from "../functions"

export const LoginHookForm = () => {
  const { setIsLogin,setEmail,setUserType,setUserId } = useContext(MainContext)

  const schema = yup.object({
    email : yup.string().email("이메일 형식을 지켜주시길 바랍니다.").required("이메일은 필수로 적어주셔야 할 사항입니다."),
    password : yup.string().min(4, "비밀번호는 최소 4글자 이상입니다.").max(16, "비밀번호는 최대 16글자 이하입니다.").required("비밀번호는 필수로 적어주셔야 할 사항입니다.")
  })
  const { register,handleSubmit,formState:{errors} } = useForm({
    resolver : yupResolver(schema)
  })
  const onSubmit = async (data) => {
    const origin_url = process.env.REACT_APP_BACKEND_URL 
    const url = `${origin_url}/user/login`
    try {
      const response = await postLoginFetch(url,data)
      if ( response ) {
       const item_dic = {
        message : response["message"],
        user_id : response["payload"]["user_id"],
        email : response["payload"]["sub"]["email"],
        user_type : response["payload"]["sub"]["user_type"],
       } 
  
       setIsLogin(true)
       localStorage.setItem("islogins","true")
  
       setEmail(item_dic.email)
       localStorage.setItem("email",item_dic.email)
  
       setUserId(item_dic.user_id)
       localStorage.setItem("user_id",item_dic.user_id)
  
       setUserType(item_dic.user_type)
       localStorage.setItem("user_type",item_dic.user_type)
  
       alert(item_dic.message)
      }
    } catch (err) {
      alert(err)
      throw err
    }
  }

  return { register,handleSubmit,errors,onSubmit }
}