import { FaMagnifyingGlass } from "react-icons/fa6";
import {  makeUserBox } from "../functions";
import { useGetUserAdmin } from "../hooks/getUserHook";
import { useClickSaltHooks } from "../hooks/clickSalthooks";
import { useGetUserEmailValueHooks } from "../hooks/getUserMessagHooks";

export const UserListsComponents = () => {
  const origin_url = process.env.REACT_APP_BACKEND_URL;
  const { userBoxes, setUserBoxes } = useGetUserAdmin(origin_url);
  const { clickSaltbtn } = useClickSaltHooks();
  const { noContent } = useGetUserEmailValueHooks(setUserBoxes, origin_url);

  return (
    <div className="user">
      <div className="userHead">
        <div className="userHeadTop">
          <h2>고객목록</h2>
          <FaMagnifyingGlass /> {/* 회원검색 */}
        </div>
        <div className="sortingFunc">
          {clickSaltbtn && <input className="userEmailInput" />} 
        </div>
      </div>
      <div className="userListContainer">
        <div className="userList">
          {noContent ? (
            <div>존재하는 이메일이 없습니다.</div>
          ) : (
            userBoxes.map((user) => makeUserBox(user)))
          }
        </div>
      </div>
    </div>
  );
};