import { useEffect,useState } from "react"
import MainImg from "../statics/main_img.png"
import { getUserEmailFetch } from "../functions"

export const useGetUserAdmin = ( origin_url ) => {
  const [userBoxes, setUserBoxes] = useState([]);
  const getData = async (url) => {
    try {
      const response = await getUserEmailFetch(url);
      if (response) {
        const uBoxes = response.map((data, idx) => (
          {
          ...data,
          user_img: MainImg,
          key: idx
        }));
        
        setUserBoxes(uBoxes);
      }
    } catch (err) {
      alert(err);
      throw err;
    }
  };

  useEffect(() => {
    const url = `${origin_url}/admin/getusers`;
    if (userBoxes.length === 0) {
      getData(url);
    }

    return () => {
      // Clean up function
    };
  }, [origin_url,userBoxes]);

  return {userBoxes,setUserBoxes}
}