import { useEffect } from "react"
import { postSendFetch } from "../functions"

export const useAiWebsocketMessageHook = (aiws,setChatBox,icon) => {
  const userId = localStorage.getItem("user_id")
  const websocket_ai_origin_url = process.env.REACT_APP_AIBOT_WEBSOCKET_URL
  const websocket_ai_url = `${websocket_ai_origin_url}/chatbot/${userId}/`

  useEffect(() => {
    aiws.current = new WebSocket(websocket_ai_url)
    aiws.current.onmessage = async (event) => {
      const aiData = await JSON.parse(event.data)
      if (aiData) {
        const backend_origin_url = process.env.REACT_APP_BACKEND_URL
        const backend_url = `${backend_origin_url}/auth/send/${userId}`
        try {
          const message = await postSendFetch(backend_url,aiData)
          if (message) {
            const aimbox = (
              <div className="aiChat">
                <img src={icon} alt="Bot icon" width={50}/>
                <div className="aiBalloon">{aiData["message"]}</div>
              </div>
            )
            setChatBox(cb => [...cb,aimbox])
          }
        } catch(err) {
          alert(err)
          throw err
        }
      }
    }
    return () => {
      aiws.current.close()
    }
  },[websocket_ai_url,userId,setChatBox,aiws,icon])
}