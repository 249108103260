import { useState,useEffect } from "react"
import { getUserEmailFetch } from "../functions"
import img from "../statics/main_img.png"

export const useGetUserEmailValueHooks = (setUserBoxes,origin_url) => {
  const [ noContent,setNoContent ] = useState(false)
  const writeEmail = (backendUrl,setUserBoxes,img) => async (event) => {
    if (event.target.className === "userEmailInput") {
      const userEmailParse = event.target.value
      let url = ""
      userEmailParse ? url = `${backendUrl}/admin/getusers/${userEmailParse}` : url = `${backendUrl}/admin/getusers`
      const response = await getUserEmailFetch(url)
      if (response !== null) {
        setNoContent(false)
        const msData = response.map((r,idx) => {
          return {
            ...r,
            key : idx,
            user_img : img
          }
        })
        setUserBoxes(msData)
      } else {
        setNoContent(true)
      }
    }
  }
  
  useEffect(() => {
    const newWriteEmail = writeEmail(origin_url,setUserBoxes,img)
    window.addEventListener("input",newWriteEmail)
    return () => {
      window.removeEventListener("input",newWriteEmail)
    }
  },[origin_url,setUserBoxes])

  return {noContent}
}