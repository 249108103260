

export const getAdminLogoutFetch = async (url) => {
  try {
    const response = await fetch(url,{
      method: "GET",
      headers : {
        "Content-Type" : "application/json"
      },
      credentials : "include",
    })
    if (!response.ok) {
      if (response.status === 404) {
        console.log("시스템 오류: ",response.status)
        return
      } else if (response.status === 401) {
        throw new Error("인증에 문제가 있습니다 다시 메인페이지로 간 후 로그인해주시길 바랍니다")
      } else {
        throw new Error("서버에 문제가 발생했습니다", response.status)
      }
    }
    const data = await response.json()
    return data

  } catch (err) {
    throw err
  }
}