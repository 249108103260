import { useEffect,useContext } from "react"
import { MainContext } from "../../../App.js"
import { getAdminLogoutFetch } from "../functions"

export const useAdminLogoutHook = () => {
  const backend_origin_url = process.env.REACT_APP_BACKEND_URL
  const { setIsLogin,setEmail,setUserType,setUserId } = useContext(MainContext)
  const clickLogoutBtn = (origin_url,setIsLogin,setEmail,setUserType,setUserId) => async (event) => {
    if (event.target.className === "logoutBtn") {
      setIsLogin(false)
      localStorage.setItem("islogins","false")

      setEmail("")
      localStorage.setItem("email","")

      setUserType("")
      localStorage.setItem("user_type","")

      setUserId("")
      localStorage.setItem("user_id","")

      const url = `${origin_url}/auth/logout`
      const response = await getAdminLogoutFetch(url)
      if (response) {
        alert(response["message"])
        window.location.href = "/"
      }
    }
  }

  useEffect(() => {
    const newClickLogoutBtn = clickLogoutBtn(backend_origin_url,setIsLogin,setEmail,setUserType,setUserId)
    window.addEventListener("click",newClickLogoutBtn)
    return () => {
      window.removeEventListener("click",newClickLogoutBtn)
    }
  },[backend_origin_url,setIsLogin,setEmail,setUserType,setUserId])
}