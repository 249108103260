import { useState } from "react"

export const ClickLoginHook = () => {
  const [ clickLogin,setClickLogin ] = useState(false)
  const clickLoginBtn = (event) => {
    setClickLogin(true)
  }
  const clickSignupBtn = (event) => {
    setClickLogin(false)
  }

  return { clickLogin,clickLoginBtn,clickSignupBtn }
}