import { useEffect } from "react"
import { getLogoutFetch } from "../functions"

export const useLogoutHook = (setIsLogin,setEmail,setUserType,setUserId) => {
  const clickLogoutBtn = (setIsLogin,setEmail,setUserType,setUserId) => async (event) => {
    if (event.target.id === "logout") {
      setIsLogin(false)
      localStorage.setItem("islogins", "false")

      setEmail("")
      localStorage.setItem("email","")

      setUserType("")
      localStorage.setItem("user_type","")

      setUserId("")
      localStorage.setItem("user_id","")

      const origin_url = process.env.REACT_APP_BACKEND_URL
      const url = `${origin_url}/auth/logout`
      try {
        const response = await getLogoutFetch(url)
        if (response) {
          alert(response["message"])
        }
      } catch(err) {
        alert(err)
        throw err
      }

      window.location.href = "/"
    
    }
  }
  useEffect(() => {
    const handleLogoutBtn = clickLogoutBtn(setIsLogin,setEmail,setUserType,setUserId)
    window.addEventListener("click", handleLogoutBtn) 
    return () => {
      window.removeEventListener("click", handleLogoutBtn)
    }
  },[setIsLogin,setEmail,setUserType,setUserId])
}