import "./statics/Css.css";
import MainImg from "./statics/main_img.png"
import { AiOutlineSwapRight } from "react-icons/ai";
import { LoginSignUp, User } from "./components"
import { useContext } from "react";
import { MainContext } from "../../App";
import { ClickLoginHook } from "./hooks";

export const Main = () => {
  const customer = "고객"
  const { isLogins } = useContext(MainContext)
  const { clickLogin,clickLoginBtn,clickSignupBtn } = ClickLoginHook()

  return(
    <div style={{ display: "flex", justifyContent: "center", alignItems:"center", height:"100vh"}}>
      <div className="mainContainer">
        <div className="mainInform">
            <img src={MainImg} alt="backImg"/>
            <div className="mainMent">
                <h1>안녕하세요 {customer}님!</h1>
                <p>마리하우스 고객상담 AI 에이전트 <strong>MaryBot</strong>입니다.<br />간단한 가입후 궁금한것 을 물어보세요!</p>
            </div>
            {
              !isLogins &&
              <div className="arrowCTA">{ clickLogin  ? "지금 로그인 하기" : "바로 가입하기"} <AiOutlineSwapRight /></div>
            }
        </div>
        <div className="tools">
          {
            isLogins ? <User />  : <LoginSignUp clickLogin={clickLogin} clickLoginBtn={clickLoginBtn} clickSignupBtn={clickSignupBtn} />

          }
        </div>
      </div>
    </div>
  )
} 