import { FaBars } from "react-icons/fa6";

export const getUserEmailFetch = async (url) => {
  try {
    const response = await fetch(url, {
      method : "GET",
      headers : {
        "Content-Type" : "application/json",
      },
      credentials : 'include',
    })
    if (!response.ok) {
      if (response.status === 401) {
        throw new Error("인증 문제가 발생했습니다 다시 로그인 해주세요")
      } else if (response.status === 404) {
        console.log("에러발생: ",response.status)
        return
      } else {
        throw new Error(`서버에 문제가 발생했습니다. 문제번호: ${response.status}`)
      }
    }
    const data = await response.json()
    return data

  } catch (err) {
    throw err
  }
}

export const makeUserBox = ( {email,user_id,user_img,key} ) => {
  return (
    <div className="userBox" key={key}>
    <div className="userBoxL">
      <div className="userIcon">
        <img src={user_img} alt="user" />
      </div>
      <div>
        <div id={user_id} className="userEmail">{email}</div>
        {/* 기존엔 id값으로 e-mail주소를 받음 */}
        <div className="userState">
          {/* <div className="userStateDate">1일전 접속</div>
          <div className="userStateDot"></div> */}
        </div>
      </div>
    </div>
    <div>
      <FaBars />
    </div>
  </div>
  )
}