import { useContext } from "react"
import { Link } from "react-router-dom"
import { MainContext } from "../../../App"
import { LogoutHook } from "../hooks"

export const User = () => {
  const { userType,setIsLogin,setEmail,setUserType,setUserId } = useContext(MainContext)
  const { clickLogoutBtn } = LogoutHook(setIsLogin,setEmail,setUserType,setUserId)

  return (
    <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'100%'}}>
      <div>{ `${userType} 페이지 입니다.` }</div>
      <button onClick={clickLogoutBtn}>로그 아웃</button>
      <button>
        {
          userType === "USER" ? <Link to="/chat/">챗팅 하러 가기</Link> : <Link to="/admin/">관리자 페이지 가기</Link>
        }
      </button>
    </div>
  )
}