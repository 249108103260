import { useState,useEffect } from "react"

export const useClickSaltHooks = () => {
  const [ clickSaltbtn,setClickSaltbtn ] = useState(false)
  const clickSalting = (event) => {
    if (event.target.className === "sortingFunc" || event.target.className === "userEmailInput") {
      setClickSaltbtn(true)
    } else {
      setClickSaltbtn(false)
    }
  }
  useEffect(() => {
    window.addEventListener("click", clickSalting)
    return () => {
      window.removeEventListener("click",clickSalting)
    }
  },[])

  return {clickSaltbtn}
}