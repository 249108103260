

export const postLoginFetch = async ( url,datas ) => {
  try {
    const response = await fetch(url, {
      method : "POST",
      headers : {
        "Content-Type" : "application/json",
      },
      body : JSON.stringify(datas),
      credentials : "include",
    })

    if ( !response.ok ) {
      if ( response.status === 500 ) {
        throw new Error("비밀번호 혹은 이메일이 틀렸습니다")
      } else {
        throw new Error(`서버에 문제가 발생했습니다. (오류 번호: ${response.status} ) `)
      }
    }

    const data = await response.json()
    return data

  } catch ( err ) {
    throw err 
  }
}