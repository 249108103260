

export const getLogoutFetch = async (url) => {
  try {
    const response = await fetch(url, {
      method : "GET",
      headers : {
        "Content-Type" : "application/json"
      },
      credentials : "include",
    })
    if (!response.ok) {
      if ( response.status === 401 ) {
        throw new Error("인증에 문제가 발생했습니다 새로고침 후 다시 이용해 주시길 바랍니다")
      } else if (response.status === 404) {
        console.log("시스템 오류: ",response.status)
        return 
      } else {
        throw new Error(`서버에 문제가 발생했습니다 문제번호: ${response.status}`)
      }
    }
    const data = await response.json()
    return data

  } catch (err) {
    throw err 
  }
}