import { SignUpHookForm } from "../hooks";
import "../statics/Css.css";


export const SignUp = () => {
  const { register,handleSubmit,errors,onSubmit } = SignUpHookForm()

  return (
    <div>
      <h1>MariBot</h1>
      <div>
        <form className="inputForm" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <input 
            type="text" 
            placeholder="이메일 입력" 
            name="email" 
            { ...register("email") }/>
            { errors.email?.message && <p>{errors.email.message}</p> }
          </div>
          <div>
            <input
              type="password"
              placeholder="비밀번호 입력"
              name="password"
              { ...register("password") }/>
              { errors.password?.message && <p>{errors.password.message}</p> }
          </div>
          <div>
            <input
              type="password"
              placeholder="비밀번호 확인"
              name="confirm_password"
              { ...register("confirm_password") }/>
              { errors.confirm_password?.message && <p>{errors.confirm_password.message}</p> }
          </div>
          <div>
            <input className="inputBtn" type="submit" value="회원가입" />
          </div>
        </form>
      </div>
    </div>
  );
}