import { useEffect,useState } from "react"
import { GetProfileUser } from "../functions"

export const GetProfileHook = () => {
  const [ user_id,setUserId ] = useState(null)
  const [ userFirstName,setUserFirstName ] = useState(null)
  const [ userLastName,setUserLastName ] = useState(null)
  const [ userPhone,setUserPhone ] = useState(null)
  const [ userEmail,setUserEmail ] = useState(null)
  const [ userNickName,setUserNickName ] = useState(null)
  const [ userAddress,setUserAddress ] = useState(null)
  const backend_origin_url = process.env.REACT_APP_BACKEND_URL
  const clickEmailBtn = (origin_url) => async (event) => {
    if (event.target.className === "userEmail") {
      const userId = event.target.id 
      setUserId(userId)
      const url = `${origin_url}/auth/getprofile/${userId}`
      const response = await GetProfileUser(url)
      if (response) {
        setUserFirstName(response["first_name"])
        setUserLastName(response["last_name"])
        setUserPhone(response["phone"])
        setUserEmail(response["email"])
        setUserNickName(response["nickname"])
        setUserAddress(response["address"])
      }
    }
  }
  
  useEffect(() => {
    const newClickEmailBtn = clickEmailBtn(backend_origin_url)

    window.addEventListener("click", newClickEmailBtn)
    return () => {
      window.removeEventListener("click", newClickEmailBtn)
    }
  },[backend_origin_url])

  return { userFirstName,userLastName,userPhone,userEmail,userNickName,userAddress,user_id}
}