import { useState,useEffect,useRef } from "react"
import { MakeMessageBox } from "../functions"

export const useWebsocketHook = ( icon ) => {
  const [ chatBox,setChatBox ] = useState([])
  const ws = useRef(null)
  const userId = localStorage.getItem("user_id")
  const websocket_backend_origin_url = process.env.REACT_APP_BACKEND_WEBSOCKET_URL
  const websocket_backend_url = `${websocket_backend_origin_url}/messages/${userId}/${userId}`
  useEffect(() => {
    ws.current = new WebSocket(websocket_backend_url)
    ws.current.onmessage = async ( event ) => {
      if (event.data) {
        const message = await JSON.parse(event.data)
        if (message) {
          const messageBoxFront = MakeMessageBox(message,icon)
          setChatBox(messageBoxFront)
        }
      }
    }
    return () => {
      ws.current.close()
    }
  },[websocket_backend_url,setChatBox,icon])
  return {chatBox,setChatBox}
}