import { useContext,useRef } from "react";
import "./statics/Css.css";
import icon from "./statics/maribot_icon.png"
import { CiPaperplane } from "react-icons/ci";
import { FaArrowRightFromBracket } from "react-icons/fa6";
import { MainContext } from "../../App";
import { useLogoutHook,useSendHook,useWebsocketHook,useAiWebsocketMessageHook } from "./hooks";


export const Chat = () => {
  const { email,userType,setIsLogin,setEmail,setUserType,setUserId } = useContext(MainContext)
  useLogoutHook( setIsLogin,setEmail,setUserType,setUserId )
  const { chatBox,setChatBox } = useWebsocketHook(icon)
  const aiws = useRef(null)
  useAiWebsocketMessageHook(aiws,setChatBox,icon)
  const { register,handleSubmit,errors,onSubmit } = useSendHook(setChatBox,aiws)

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div>
        <div className="chatCover">
          <div>
            <div style={{margin:'0px 20px 20px 20px'}}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                  }}
                >
                  <h1 style={{ marginBottom: "0" }}>MaryBot</h1>
                  <button id="logout" className="logoutBtn">
                    <FaArrowRightFromBracket /> Log Out
                  </button>
                </div>
                <div>{email}</div>
                <div>{userType}</div>
            </div>
          </div>
          <div id="chatBox">
            {chatBox}
          </div>
          <form className="chatForm" onSubmit={handleSubmit(onSubmit)}>
            <textarea 
            name="message"
            { ...register("message") }
            ></textarea>
            {errors.message?.message && <p>{ errors.message.message }</p>}
            <button>
              <CiPaperplane />
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
