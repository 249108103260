import "../statics/Css.css";
import { LoginHookForm } from "../hooks"

export const Login = () => {
  const { register,handleSubmit,errors,onSubmit } = LoginHookForm()

  return (
    <div>
      <h1>MariBot</h1>
      <div>
        <form className="inputForm" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <input 
            type="text" 
            placeholder="이메일 입력" 
            name="email" 
            {...register("email")}/>
            { errors.email?.message && <p>{errors.email.message}</p> }
          </div>
          <div>
            <input
              type="password"
              placeholder="비밀번호 입력"
              name="password"
              {...register("password")}/>
              { errors.password?.message && <p>{errors.password.message}</p> }
          </div>

          <div>
            <input className="inputBtn" type="submit" value="로그인" />
          </div>
        </form>
      </div>
    </div>
  );
}