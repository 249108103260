import { Login } from "./Login"
import { SignUp } from "./SignUp"

export const LoginSignUp = ({clickLogin,clickLoginBtn,clickSignupBtn}) => {

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems:"center", height:"100%"}}>
      <div className="signUpCover">
        {
          clickLogin ? <Login /> : <SignUp />
        }
        <div className="signImUpBtn">
          <button onClick={clickLoginBtn}>
            로그인
          </button> /
          <button onClick={clickSignupBtn}>
            회원가입
          </button>
        </div>
      </div>
    </div>
  )
}