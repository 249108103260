

export const postSignUpFetch = async ( url,datas ) => {
  try {
    const response = await fetch(url, {
      method : "POST",
      headers : {
        "Content-Type" : "application/json"
      },
      body : JSON.stringify(datas)
    })
  
    if ( !response.ok ) {
      if ( response.status === 500 ) {
        throw new Error("이미 존재하는 이메일 입니다.")
      } else  {
        throw new Error(`서버에 오류가 발생했습니다 (오류 번호: ${response.status} )`)
      }
    }
  
    const data = await response.json()
    return data
  } catch ( err ) {
    throw err
  }
} 