import { BrowserRouter as Routers, Routes, Route } from "react-router-dom"
import { Main } from "./pkgs/mains/Main.js"
import { Error } from "./pkgs/errors/Error.js"
import { createContext } from "react"
import { useLoginContext } from "./settings"
import { Chat } from "./pkgs/chats/Chat.js"
import { Admin } from "./pkgs/admins/Admin.js"

export const MainContext = createContext()

function App() {
  const { isLogins,email,userType,userId,setIsLogin,setEmail,setUserType,setUserId } = useLoginContext()
 
  return (
    <div className="App">
      <MainContext.Provider value={{ isLogins,email,userType,userId,setIsLogin,setEmail,setUserType,setUserId }}>
        <Routers>
          <Routes>
            <Route path="/" element = {<Main />}/>
            <Route path="/chat/" element = {<Chat />}/>
            <Route path="/admin/" element = { <Admin /> }/>
            <Route path="*" element = {<Error />}/>
          </Routes>
        </Routers>
      </MainContext.Provider>
    </div>
  );
}

export default App;
