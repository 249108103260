import { useState,useEffect } from "react"

export const useLoginContext = () => {
  const [ isLogins,setIsLogin ] = useState(false)
  const [ email,setEmail ] = useState("")
  const [ userType,setUserType ] = useState("")
  const [ userId,setUserId ] = useState("")
  useEffect(() => {
    const loginCheck = localStorage.getItem("islogins")
    if (loginCheck === "true") {
      setIsLogin(true)

      const email = localStorage.getItem("email")
      const user_type = localStorage.getItem("user_type")
      const user_id = localStorage.getItem("user_id")

      setEmail(email)
      setUserType(user_type)
      setUserId(user_id)
    }
  }, [])

  return { isLogins,email,userType,userId,setIsLogin,setEmail,setUserType,setUserId }
}