import { FaMagnifyingGlass } from "react-icons/fa6";
import MainImg from "../statics/main_img.png";
import { useGetMessageAdminHooks } from "../hooks/getMessageHook";
import { useGetDotAndTextHook } from "../hooks/getUserDotTextHook.js";

export const AdminPageUserMessage = ({ userEvents }) => {
  const { msBox,pickEmail,pickUserId } = useGetMessageAdminHooks()
  const { dotColor,dotText } = useGetDotAndTextHook(pickUserId,userEvents)

  return (
    <div className="chatDetail">
      <div className="chatDetailHead">
        <div className="userBox">
          <div className="userBoxL">
            <div className="userIcon">
              <img src={MainImg} alt="user" />
            </div>
            <div>
              { pickEmail && 
              <div>
                <div className="userEmail">{pickEmail}</div>{" "}
              {/* 기존엔 id값으로 e-mail주소를 받음 */}
                <div className="userState">
                  {/* <div className="userStateDate">1일전 접속</div> */}
                  <div className="userStateDot" style={{backgroundColor:dotColor}} ></div>
                  <div>{dotText}</div>
                </div>
              </div>
              }
            </div>
          </div>
          <div>
            <FaMagnifyingGlass />
            {/* 채팅 내용 검색 */}
          </div>
        </div>
      </div>
      <div className="horizontalLine" /> {/*가로 분리선*/}

      {/* 채팅 박스 ai - user 번갈아 */}
      <div id="chatBox">
        {msBox}
      </div>
    </div>
  )
}