import { useState,useEffect,useRef } from "react"

export const useGetUserEvents = () => {
  const [userEvents, setUserEvents] = useState([]);
  const eventWs = useRef(null);
  const origin_websocket_url = process.env.REACT_APP_BACKEND_WEBSOCKET_URL;
  const userId = localStorage.getItem("user_id")

  useEffect(() => {
    const url = `${origin_websocket_url}/getevents/${userId}`;
    eventWs.current = new WebSocket(url);
    eventWs.current.onmessage = async (event) => {
      const response = await JSON.parse(event.data);
      if (response) {
        setUserEvents(response);
      }
    };
    return () => {
      eventWs.current.close();
    };
  }, [userId, origin_websocket_url]);

  return {userEvents}
}