import { useRef,useEffect,useState } from "react"
import { makeMessageBox } from "../functions"

export const useGetMessageAdminHooks = () => {
  const [ pickEmail,setPickEmail ] = useState("")
  const [ pickUserId,setPickUserId ] = useState("")
  const websokcet_origin_url = process.env.REACT_APP_BACKEND_WEBSOCKET_URL
  const msws = useRef(null)
  const [ msBox,setMsBox ] = useState([])
  const userId = localStorage.getItem("user_id")

  const clickUserEmail = (websokcet_origin_url,userId,ws,setPickEmail) => (event) => {
    if ( event.target.className === "userEmail" ) {
      const user_id = event.target.id
      const user_email = event.target.innerHTML
      setPickEmail(user_email)
      setPickUserId(user_id)
      const websocket_url = `${websokcet_origin_url}/messages/${user_id}/${userId}`
      if (ws.current) {
        setMsBox([])
        ws.current.close()
      }
      ws.current = new WebSocket(websocket_url)
      ws.current.onmessage = async ( event ) => {
        if (event.data) {
          const messages = await JSON.parse(event.data)
          if (messages) {
            const messageBox = messages.map((message,idx) => {
              message["key"] = idx
              return makeMessageBox(message)
            })
            setMsBox(messageBox)
          }
        }
      }
    }
  }
  
  useEffect(() => {
    const newClickUserEmail = clickUserEmail(websokcet_origin_url,userId,msws,setPickEmail)
    window.addEventListener("click",newClickUserEmail)
    return () => {
      window.removeEventListener("click",newClickUserEmail)
    } 
  },[websokcet_origin_url,userId,msws])

  return {msBox,pickEmail,pickUserId}
}