import { FaInbox } from "react-icons/fa";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { FaGear } from "react-icons/fa6";
import logo from "../statics/mary_logo.png"
import { useAdminLogoutHook } from "../hooks";

export const AdminNavbar = () => {
  useAdminLogoutHook()

  return (
    <div className="sideMenu">
    <div>
      <img src={logo} alt="logo" />
    </div>
    <div className="sideMenu_bottom">
      <div className="logoutBtn">
        logout
      </div>
      <FaInbox />
      <FaMagnifyingGlass />
      <FaGear />
    </div>
  </div>
  )
}