import "./statics/Css.css";
import { AdminNavbar, AdminPageUserMessage, UserInfoPage, UserListsComponents } from "./components";
import { useGetUserEvents } from "./hooks";

export const Admin = () => {
  const { userEvents } = useGetUserEvents()

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
      <div className="adminContainer">
        {/* 사이드 메뉴 ------------------------------------ */}
        <AdminNavbar />

        <div className="verticalLine" /> {/*세로 분리선*/}
        {/* 유저 목록 ------------------------------------ */}
        <UserListsComponents />

        <div className="verticalLine"/> {/*세로 분리선*/}
        {/* 채팅내용 확인 */}
        <AdminPageUserMessage userEvents={userEvents}/>

        <div className="verticalLine" /> {/*세로 분리선*/}
        {/* 고객 정보 */}
        <UserInfoPage userEvents={userEvents}/>
      </div>
    </div>
  );
};
