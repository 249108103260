import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { postSendFetch } from "../functions"

export const useSendHook = (setChatBox,aiws) => {
  const schema = yup.object({
    message : yup.string().max(500,"메세지는 최대 500글자만 입력가능합니다").required("메세지 창 입력은 필수사항입니다."),
  })  
  const { register,handleSubmit,formState:{errors} } = useForm({
    resolver : yupResolver(schema)
  })
  const userId = localStorage.getItem("user_id")
  const onSubmit = async (data) => {
    const allMessages = document.querySelectorAll(".messageData")
    const messageData = Array.from(allMessages).map(message => {
      return ({
        message : message.innerHTML,
        type : message.getAttribute("data-value")
      })
    })
    data["type"] = "USER"
    messageData.push(data)
    aiws.current.send(JSON.stringify(messageData))
    const newMs = (
      <div className="userChat">
        <div className="userBalloon">{data["message"]}</div>
      </div>
    )
    setChatBox(cb => [...cb,newMs])
    const origin_url = process.env.REACT_APP_BACKEND_URL
    const url = `${origin_url}/auth/send/${userId}`
    try {
      const response = await postSendFetch(url,data)
      if (response) {
        console.log(response["message"])
      } 
       
    } catch (err) {
      alert(err)
      throw err 
    }
  }

  return { register,handleSubmit,errors,onSubmit }
}