import { getLogoutFetch } from "../functions"

export const LogoutHook = (setIsLogin,setEmail,setUserType,setUserId) => {
  const origin_url = process.env.REACT_APP_BACKEND_URL
  const url = `${origin_url}/auth/logout`
  const clickLogoutBtn = async (event) => {
    setIsLogin(false)
    localStorage.setItem("islogins","false")
    
    setUserId("")
    localStorage.setItem("email","")
    
    setEmail("")
    localStorage.setItem("user_type","")
    
    setUserType("")
    localStorage.setItem("user_id","")
    try {
      const response = await getLogoutFetch(url)
      if (response) {
        alert(response["message"])
      }
    } catch (err) {
      alert(err)
      throw err
    }
  }

  return { clickLogoutBtn }
}