import { useState,useEffect } from "react"

export const useGetDotAndTextHook = (user_id,userEvents) => {
  const [ userEvent,setUserEvent ] = useState("")
  useEffect(() => {
    userEvents.forEach(element => {
      if (element["user_id"] === user_id) {
        setUserEvent(element["type"])
      }
    });
  }, [ user_id,userEvents ])

  const [ dotColor,setDotColor ] = useState("")
  const [ dotText,setDotText ] = useState("")
  useEffect(() => {
    if (userEvent === "a") {
      setDotColor("green")
      setDotText("접속중")
    } else if (userEvent === "b") {
      setDotColor("yellow")
      setDotText("부재중")
    } else {
      setDotColor("#c7c7c7")
      setDotText("연결종료")
    }

  },[ userEvent ])

  return { dotColor,dotText }
}