import MainImg from "../statics/main_img.png";
import { BiSolidUserRectangle } from "react-icons/bi";
import { IoPhonePortraitOutline } from "react-icons/io5";
import { CiMail } from "react-icons/ci";
import { FaUserCircle } from "react-icons/fa";
import { FiMapPin } from "react-icons/fi";
import { FiFileText } from "react-icons/fi";
import { GetProfileHook, useGetDotAndTextHook } from "../hooks"

export const UserInfoPage = ( { userEvents } ) => {
  const { userFirstName,userLastName,userPhone,userEmail,userNickName,userAddress,user_id } = GetProfileHook(userEvents)
  const { dotColor,dotText } = useGetDotAndTextHook(user_id,userEvents)

  return (
  <div className="userInfo">
    <div className="userInfoHead">
      <h2>고객정보</h2>
      <BiSolidUserRectangle />
    </div>

  {/* 고객 아이콘 & 이름 */}
    <div style={{ display: "flex", alignItems: "center",fontWeight:"600",gap:"5px" }}>
      <div className="userIcon">
        <img src={MainImg} alt="user" />
      </div>
      <div style={{ display: "flex", alignItems: "center", gap:"5px" }}>
          <div style={{fontWeight:"600"}}>{userFirstName && userLastName && userFirstName + userLastName}</div>
      </div>
    </div>

  {/* 고객 전화번호 */}
    <div className="userInfos">
      <div style={{ display: "flex", alignItems: "center", gap:"5px" }}>
          <IoPhonePortraitOutline />
          <div className="userInfomn">전화번호</div>
      </div>
      <div className="userInfoaw">{userPhone}</div>
    </div>

  {/* 고객 Email */}
    <div className="userInfos">
      <div style={{ display: "flex", alignItems: "center", gap:"5px" }}>
          <CiMail />
          <div className="userInfomn">메일</div>
      </div>
      <div className="userInfoaw">{userEmail}</div>
    </div>

  {/* 최근 접속시간 */}
    <div className="userInfos">
      <div style={{ display: "flex", alignItems: "center", gap:"5px" }}>
        <div className="userStateDot" style={{backgroundColor:dotColor}}></div> 
        <div>{dotText}</div>
        
        {/* <div className="userInfomn">최근 접속시간</div> */}
      </div>
      {/* <div className="userInfomn">1일전 접속</div> */}
    </div>

  {/* 고객 ID */}
    <div className="userInfos">
      <div style={{ display: "flex", alignItems: "center", gap:"5px" }}>
          <FaUserCircle />
          <div className="userInfomn">회원 닉네임</div>
      </div>
      <div className="userInfoaw">{userNickName}</div>
    </div>

  {/* 고객 지역 */}
    <div className="userInfos">
      <div style={{ display: "flex", alignItems: "center", gap:"5px" }}>
          <FiMapPin />
          <div className="userInfomn">지역</div>
      </div>
      <div className="userInfoaw">{userAddress}</div>
    </div>

  {/* 고객 요약 */}
    <div className="userInfos">
        <div style={{ display: "flex", alignItems: "center", gap:"5px" }}>
            <FiFileText />
            <div className="userInfomn">고객요약</div>
        </div>
    </div>
    <div className="userInfosmr">추후 추가 예정</div>
  </div>
  )
}