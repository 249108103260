

export const getLogoutFetch = async (url) => {
  try {
    const response = await fetch(url,{
      method : "GET",
      headers : {
        "Content-Type" : "application/json",
      },
      credentials : "include",
    })
    if (!response.ok) {
      if (response.status === 401) {
        throw new Error("인증에 문제가 있습니다. 다시 홈으로가서 로그인 해주시길 바랍니다.")
      } else if (response.status === 404 ) {
        console.log("에러 발생",response.status)
        return
      } else {
        throw new Error(`오류가 발생했습니다. 오류번호: ${response.status}`)
      }
    }
    const data = await response.json()
    return data

  } catch (err) {
    throw err
  }
}

export const postSendFetch = async (url,datas) => {
  try {
    const response = await fetch(url, {
      method : "POST",
      headers : {
        "Content-Type" : "application/json",
      },
      body : JSON.stringify(datas),
      credentials : "include",
    })
    if (!response.ok) {
      if ( response.status === 401 ) {
        throw new Error("인증이 되지 않았습니다 로그 아웃 후 다시 로그인 해주시길 바랍니다")
      } else if (response.status === 404) {
        console.log("오류 발생: ",response.status)
        return
      } else {
        throw new Error(`오류가 발생했습니다. 번호: ${response.status}`)
      }
    }
    const data = await response.json()
    return data

  } catch (err) {
    throw err 
  }
}

export const MakeMessageBox = (messages, icon) => {
  const messageBoxFront = messages.map((message,idx) => {
    if (message.type === "USER") {
      return (
        <div key={idx} className="userChat">
          <div className="userBalloon">
            <div className="messageData" data-value="USER">{message.message}</div>
          </div>
        </div>
      );
    } else if (message.type === "BOT") {
      return (
        <div key={idx} className="aiChat">
          <img src={icon} alt="Bot icon" width={50}/>
          <div className="aiBalloon">
            <div className="messageData" data-value="BOT">{message.message}</div>
          </div>
        </div>
      );
    } else {
      return (
        <div key={idx} className="aiChat">
          <img src={icon} alt="Bot icon" width={50}/>
          <div className="aiBalloon">{message.message}</div>
        </div>
      );
    }
  });
  return messageBoxFront;
};

