import img from "../statics/maribot_icon.png"

export const makeMessageBox = ({type,message,key}) => {
  const TypeDic = {
    BOT : ["aiChat","aiBalloon",img],
    USER : ["userChat","userBalloon",null],
    ADMIN : ["aiChat","aiBallon",img],
  }
  return (
    <div className={TypeDic[type][0]} key={key}>
      {TypeDic[type][2] != null && <img src={TypeDic[type][2]} alt="icon" width="50" />}
      <div className={TypeDic[type][1]}>
        {message}
      </div>
    </div>
  )
}