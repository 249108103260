import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { postSignUpFetch } from "../functions"

export const SignUpHookForm = () => {
  const schema = yup.object({
    email : yup.string().email("이메일 형식을 지켜주시길 바랍니다.").required("이메일은 필수로 적어주셔야 할 사항입니다."),
    password : yup.string().min(4, "비밀번호는 최소 4글자 이상입니다.").max(16, "비밀번호는 최대 16글자 이하입니다.").required("비밀번호는 필수로 적어주셔야 할 사항입니다."),
    confirm_password : yup.string().oneOf([yup.ref("password")],"비밀번호를 확인해주시길 바랍니다.")

  })
  const { register,handleSubmit,formState:{errors} } = useForm({
    resolver : yupResolver(schema)
  })
  const onSubmit = async (data) => {
    delete data.confirm_password
    data["user_type"] = "USER"
    const origin_url = process.env.REACT_APP_BACKEND_URL
    const url = `${origin_url}/user/signup`
    try {
      const response = await postSignUpFetch(url,data)
      if ( response ) {
        alert(response["message"])
      }
      
    } catch (err) {
      alert(err)
      throw err
    }
  }

  return { register,handleSubmit,errors,onSubmit }
}