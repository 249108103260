

export const GetProfileUser = async (url) => {
  try {
    const response = await fetch(url,{
      method : "GET",
      headers : {
        "Content-Type":"application/json",
      },
      credentials : "include",
    })
    if (!response.ok) {
      if (response.status === 401) {
        throw new Error("인증 문제입니다 로그아웃후 다시 로그인 해주세요.")
      } else if (response.status === 404) {
        console.log("오류가 발생했습니다: ",response.status)
        return
      }
    }
    const data = await response.json()
    return data

  } catch (err) {
    throw err
  }
} 